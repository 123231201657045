import React from "react";
const ShareIcon = ({
    viewBox = '0 0 24 24',
    size = 24,
    color = "#272636",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="47596"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M5.064339 94.782119l0-74.338917 494.595401 0c17.302383 0 31.352438 16.614748 31.352438 37.206628 0 20.517541-14.050055 37.132289-31.352438 37.132289L5.064339 94.782119M1008.639721 1024l-74.338917 0L934.300804 529.404599c0-17.302383 16.614748-31.352438 37.206628-31.352438 20.517541 0 37.132289 14.050055 37.132289 31.352438L1008.639721 1024M1008.639721 20.443202 945.972014 20.443202 1008.639721 20.443202ZM1008.639721 83.129494 1008.639721 20.443202 1008.639721 83.129494ZM5.064339 83.129494 5.064339 20.443202 67.750631 20.443202 5.064339 20.443202 5.064339 83.129494ZM5.064339 1024 5.064339 961.332293 5.064339 1024ZM67.750631 1024 5.064339 1024 67.750631 1024ZM1008.639721 1024 945.972014 1024 1008.639721 1024ZM1008.639721 1024 1008.639721 961.332293 1008.639721 1024ZM934.300804 20.443202l74.338917 0 0 263.438538c0 17.302383-16.614748 31.371023-37.132289 31.371023-20.610465 0-37.206628-14.06864-37.206628-31.371023L934.300804 20.443202M726.393437 94.782119c-17.339552 0-31.371023-16.614748-31.371023-37.132289 0-20.573295 14.031471-37.206628 31.371023-37.206628l282.227699 0 0 74.338917L726.393437 94.782119M79.403256 1024 5.064339 1024 5.064339 20.443202 79.403256 20.443202 79.403256 1024ZM1008.639721 949.661083 1008.639721 1024 5.064339 1024 5.064339 949.661083 1008.639721 949.661083ZM947.941995 28.564729c12.210167-12.265921 33.935716-10.426033 48.431805 4.107225 14.551843 14.477504 16.391731 36.221637 4.107225 48.431805L288.425706 793.214831c-12.265921 12.321676-33.9543 10.481787-48.506143-4.12581-14.533258-14.458919-16.373147-36.221637-4.107225-48.394635L947.941995 28.564729"
      fill={color}
      p-id="47597"
    ></path>
  </svg>
);


export default ShareIcon;