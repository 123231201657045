import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/terms.scss";
import sec6_people from "../image/sec6-people.jpg";
import contact from "../image/contact.webp";

class Terms extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="return-page">
          <div className="left">
            <div className="title">Terms and Conditions</div>
            <p>Terms and Conditions</p>
            <p>
              Please read these Terms and Conditions (“Terms”, “Terms and
              Conditions”) carefully before using the http://www.lelaburris.com
              website (the “Service”) operated by LelaBurris.com (“us”, “we”, or
              “our”).
            </p>
            <p>
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all visitors, users and others who access or use the Service.
            </p>
            <p>
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms then you may not
              access the Service.
            </p>
            <p>Accounts</p>
            <p>
              When you create an account with us, you must provide us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of your account on our Service.
            </p>
            <p>
              You are responsible for safeguarding the password that you use to
              access the Service and for any activities or actions under your
              password, whether your password is with our Service or a
              third-party service.
            </p>
            <p>
              You agree not to disclose your password to any third party. You
              must notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </p>
            <p>Comments</p>
            <p>
              Comments are welcomed and encouraged on this site, but there are
              some instances where comments will be edited or deleted as
              follows:
            </p>
            <p>
              1.Comments deemed to be spam or questionable spam will be deleted.
              Including a link to relevant content is permitted, but comments
              should be relevant to the post topic.
            </p>
            <p>2.Comments including profanity will be deleted.</p>
            <p>
              3.Comments containing language or concepts that could be deemed
              offensive will be deleted.
            </p>
            <p>4.Comments that attack a person individually will be deleted.</p>
            <p>
              The owner of this site reserves the right to edit or delete any
              comments submitted to any posts, pages, or products without
              notice. This comment policy is subject to change at any time.
            </p>
            <p>Site Content</p>
            <p>
              The photos, logos, designs, mood boards, projects, and written
              content are all property of LelaBurris.com unless otherwise
              stated. Most content is created and generated by the site owner,
              however, any photos used from brands or third party websites will
              be linked accordingly, proper credit will be given, and content
              owner will be notified by one attempt. If the owner is unable to
              be reached, the images and/or content will continue to contain
              links to its origin. We take pride in teaming up with other
              companies, so if you find that your content has been featured with
              an incorrect link, please notify us immediately so we can make it
              right. We want to be sure we share the love and send our readers
              to sites we know they’ll love.
            </p>
            <p>Links To Other Web Sites</p>
            <p>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by LelaBurris.com.
            </p>
            <p>
              LelaBurris.com has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any third
              party web sites or services. You further acknowledge and agree
              that LelaBurris.com shall not be responsible or liable, directly
              or indirectly, for any damage or loss caused or alleged to be
              caused by or in connection with use of or reliance on any such
              content, goods or services available on or through any such web
              sites or services.
            </p>
            <p>
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party web sites or services that you
              visit.
            </p>
          </div>
          <div className="right">
            <div className="title">Meet Lela Burris</div>
            <img src={sec6_people} alt="" />
            <p>
              My name is Lela, (say it like LEE-luh) and I'm so glad you stopped
              by my blog! Whether you need help organizing, decluttering,
              tweaking daily routines, or you just think my pets are cute,
              you'll find all kinds of inspo here.
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Terms;
