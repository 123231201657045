import col1 from "../image/col1.webp";
import col1_1 from "../image/col1-1.webp";
import col1_2 from "../image/col1-2.webp";
import col2 from "../image/col2.webp";
import col2_1 from "../image/col2-1.webp";
import col2_2 from "../image/col2-2.webp";
import col3 from "../image/col3.webp";
import col3_1 from "../image/col3-1.webp";
import col3_2 from "../image/col3-2.webp";
import col4 from "../image/col4.webp";
import col4_1 from "../image/col4-1.webp";
import col4_2 from "../image/col4-2.webp";
import col5 from "../image/col5.webp";
import col5_1 from "../image/col5-1.webp";
import col5_2 from "../image/col5-2.webp";
import col6 from "../image/col6.webp";
import col6_1 from "../image/col6-1.webp";
import col6_2 from "../image/col6-2.webp";
import col7 from "../image/col7.webp";
import col7_1 from "../image/col7-1.webp";
import col7_2 from "../image/col7-2.webp";
import col8 from "../image/col8.webp";
import col8_1 from "../image/col8-1.webp";
import col8_2 from "../image/col8-2.webp";
import col9 from "../image/col9.webp";
import col9_1 from "../image/col9-1.webp";
import col9_2 from "../image/col9-2.webp";
import col10 from "../image/col10.webp";
import col10_1 from "../image/col10-1.webp";
import col10_2 from "../image/col10-2.webp";
import col11 from "../image/col11.webp";
import col11_1 from "../image/col11-1.webp";
import col11_2 from "../image/col11-2.webp";
import col12 from "../image/col12.webp";
import col12_1 from "../image/col12-1.webp";
import col12_2 from "../image/col12-2.webp";

export const productList = [
  {
    id: 1,
    title: "ENJOY! GREEN GUIDE",
    img: col1,
    image: [col1_1, col1_2],
    link: "https://www.tokyu-green-resort.com/",
    description: [
      "店舗・施設紹介",
      "商品・サービス紹介",
      "情報・メディア発信",
      "観光・旅行",
      "アニメーション",
      "シンプル",
      "やさしい",
      "かわいい",
      "ポップ",
      "イラスト",
      "黄色",
      "緑色",
      "やさしい・やわらかい色",
    ],
    txt: "大自然で思いっきり楽しみたい皆さんへ、「体感型サステナブルリゾート」ガイドです。ワクワクするアクティビティからおいしい地元の食事まで、楽しみながらサステナブルな意識も芽生える思い出の旅へご案内します。",
  },
  {
    id: 2,
    title: "いなべ阿下喜ベース",
    img: col2,
    image: [col2_1, col2_2],
    link: "https://inabe-ageki-base.com/",
    description: [
      "店舗・施設紹介",
      "ホテル・旅館・温泉",
      "アニメーション",
      "シンプル",
      "やさしい",
      "かわいい",
      "ロゴが印象的",
      "控えめな配色",
    ],
    txt: "15年続いた阿下喜温泉あじさいの里が、温泉・サウナ・宿泊・食事からなる街の温泉複合施設 『いなべ阿下喜ベース』に生まれ変わりました。 いままで阿下喜温泉に通っていた地元の方も、市外からいなべ市に遊びに来られる方も楽しめる 施設として、今まで人気だった温泉はそのままに、サウナエリアやコンテナタイプの宿泊、地元のお野菜が楽しめる食堂が加わりました。 「いなべ阿下喜ベース」は、『自然と健康』をコンセプトに、いなべの自然資源をとりいれて、キャンプや登山などのアウトドア、すぐ近くの阿下喜商店街の街歩きなど、健康につながる体験の提供と観光客の市内周遊の拠点(ベース)として展開していきます。",
  },
  {
    id: 3,
    title: "irop",
    img: col3,
    image: [col3_1, col3_2],
    link: "https://irop.jp/shop",
    description: [
      "商品・サービス紹介",
      "美容・健康",
      "アニメーション",
      "シンプル",
      "やさしい",
      "かわいい",
      "ポップ",
      "インパクト",
      "イラスト",
      "青色",
      "ピンク",
      "茶色",
      "鮮やかな色",
    ],
  },
  {
    id: 4,
    title: "めおと – ふたりで楽しむ暮らしのギフト",
    img: col4,
    image: [col4_1, col4_2],
    link: "https://meoto.jp/",
    description: [
      "商品・サービス紹介",
      "家電・生活用品",
      "アニメーション",
      "シンプル",
      "やさしい",
      "ナチュラル",
      "ロゴが印象的",
      "ブラック",
      "赤色",
      "ベージュ",
      "やさしい・やわらかい色",
      "落ち着きのある色",
    ],
    txt: "「めおと」は、2人で楽しむ暮らしのギフト。暮らしになじむアイテムを通じて、二人ですごす時間を増やします。おたがいを思う気持ちと時間が、夫婦の距離を近くする。日頃のありがとうや、ねぎらいをギフトに込めて贈りませんか。",
  },
  {
    id: 5,
    title: "CLEND",
    img: col5,
    image: [col5_1, col5_2],
    link: "https://clend.jp/",
    description: [
      "商品・サービス紹介",
      "美容・健康",
      "アニメーション",
      "シンプル",
      "やさしい",
      "ナチュラル",
      "インパクト",
      "スタイリッシュ",
      "ロゴが印象的",
      "ダーク",
      "黄色",
      "グレー",
      "落ち着きのある色",
      "鮮やかな色",
    ],
    txt: "泥ヂカラで芯まで補修。地肌と髪の土台を作る保水ミネラルシャンプーで質感変わる！リッチな天然クレイで贅沢に洗って潤すヘアケアブランド",
  },
  {
    id: 6,
    title: "星野リゾート採用サイト",
    img: col6,
    image: [col6_1, col6_2],
    link: "https://hoshinoresorts.com/jp/recruit/",
    description: [
      "採用",
      "ホテル・旅館・温泉",
      "アニメーション",
      "シンプル",
      "やさしい",
      "かわいい",
      "ナチュラル",
      "ポップ",
      "イラスト",
      "カラフル",
      "やさしい・やわらかい色",
    ],
    txt: "星野リゾートの採用サイトです。歴史、MISSION/VISON/代表メッセージ、基本戦略、キャリアパスシステム、星野リゾートの人々、募集要項/職種など、働く場としての星野リゾートの情報を幅広くご紹介します！",
  },
  {
    id: 7,
    title: "安藤七宝店",
    img: col7,
    image: [col7_1, col7_2],
    link: "https://www.ando-shippo.co.jp/",
    description: [
      "商品・サービス紹介",
      "日本の文化",
      "日本の技術",
      "アニメーション",
      "シンプル",
      "かっこいい",
      "上品",
      "スタイリッシュ",
      "和風",
      "誠実・信頼",
      "ロゴが印象的",
      "グレー",
      "控えめな配色",
    ],
    txt: "創業明治13年、文化財保護委員会無形文化財選定工場の株式会社安藤七宝店公式Webサイトです。安藤七宝店は長年続いた七宝の歴史を守り、技術を継承し、今を生きる人たちとこの先も七宝の道を歩んでいきます。",
  },
  {
    id: 8,
    title: "Worker’s Resort",
    img: col8,
    image: [col8_1, col8_2],
    link: "https://www.workersresort.com/",
    description: [
      "メディア・マガジン",
      "情報・メディア発信",
      "アニメーション",
      "シンプル",
      "かっこいい",
      "スタイリッシュ",
      "ロゴが印象的",
      "ブラック",
      "青色",
      "グレー",
      "モノトーン",
    ],
    txt: "「働く」を、あたらしく。総務を、もっとクリエイティブに。Worker's Resortは、オフィスをより良い環境へと進化させていくのに必要なヒントが集まる、オフィスマネージャーのためのリサーチネットワークです。",
  },
  {
    id: 9,
    title: "さくら不動産",
    img: col9,
    image: [col9_1, col9_2],
    link: "https://sakurafudousan.jp/",
    description: [
      "店舗・施設紹介",
      "建築・不動産",
      "アニメーション",
      "シンプル",
      "やさしい",
      "誠実・信頼",
      "青色",
      "ピンク",
      "やさしい・やわらかい色",
    ],
    txt: "白馬・大町・安曇野のエリアナンバーワン不動産",
  },
  {
    id: 10,
    title: "PAQUET MONTÉ",
    img: col10,
    image: [col10_1, col10_2],
    link: "https://paquetmonte.suzu-pr.com/",
    description: [
      "店舗・施設紹介",
      "食品・飲食",
      "アニメーション",
      "シンプル",
      "ナチュラル",
      "かっこいい",
      "上品",
      "誠実・信頼",
      "ロゴが印象的",
      "ダーク",
      "ブラック",
      "グレー",
      "モノトーン",
      "落ち着きのある色",
    ],
    txt: "「ちょっとよそゆきのフランスおやつ」をコンセプトにした、日本初のflan pâtissier（フラン・パティシエ）専門店「PAQUET　MONTÉ（パケモンテ）」",
  },
  {
    id: 11,
    title: "GO!PEACE! – フェリシモ",
    img: col11,
    image: [col11_1, col11_2],
    link: "https://www.felissimo.co.jp/gopeace/",
    description: [
      "EC",
      "キャンペーン・イベント",
      "日本の文化",
      "アニメーション",
      "やさしい",
      "かわいい",
      "ポップ",
      "イラスト",
      "青色",
      "黄色",
      "緑色",
      "オレンジ",
      "ピンク",
      "カラフル",
      "やさしい・やわらかい色",
    ],
    txt: "みんなで「うれしい未来」をつくるプロジェクト。お買い物を通じて、さまざまな分野のうれしい未来を一緒につくりませんか？",
  },
  {
    id: 12,
    title: "つながる。つなげる。HOLIDAY GIFT! – グローバルワーク",
    img: col12,
    image: [col12_1, col12_2],
    link: "https://www.globalwork.jp/women/holidaygift2023/",
    description: [
      "EC",
      "アパレル・クリーニング",
      "アニメーション",
      "シンプル",
      "やさしい",
      "かわいい",
      "ポップ",
      "黄色",
      "ベージュ",
      "やさしい・やわらかい色",
    ],
    txt: "今年のグローバルワークは、ギフトを通して、想いをつなげて、笑顔がつながる、HAPPY HOLIDAY!",
  },
];

export const getProductDetailById = (id) => {
  for (let i = 0; i < productList.length; i++) {
    if (productList[i].id === id) {
      return productList[i];
    }
  }
};

export const getRandomElements = (id) => {
  const result = [];
  const arrayCopy = [...productList]; // 创建数组的副本，以避免修改原始数组

  for (let i = 0; i < 4; i++) {
    let randomIndex;

    // 确保随机索引不与给定的 id 相同
    do {
      randomIndex = Math.floor(Math.random() * arrayCopy.length);
    } while (productList[randomIndex].id === id);

    const element = arrayCopy.splice(randomIndex, 1)[0];
    result.push(element);
  }

  return result;
};
