import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import Facebook from "../../icon/Facebook";
import Instagram from "../../icon/Instagram";
import Pinterest from "../../icon/Pinterest";

import TikTok from "../../icon/TikTok";
import footImg from "../../image/footer.png";
import facebook from "../../image/facebook.svg";
import twitter from "../../image/twitter.svg";
import instagram from "../../image/instagram.svg";
import pinterest from "../../image/pinterest.svg";
import youtube from "../../image/youtube.svg";
import footer from "../../image/footer.svg";
import logo from "../../image/logo.png";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "about":
        history.push("/About");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "shipping":
        history.push("/Shipping");
        break;
      case "terms":
        history.push("/Terms");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="top-foot">
          <div className="col">
            <div className="title">Webでのご相談はこちらから!!</div>
            <div className="btn" onClick={()=>this.routePage('contact')}>無料でHP制作の相談をする</div>
          </div>
          <div className="col">
            <div className="title">お急ぎの方は電話で相談 (24時間受付中)</div>
            <div className="num">03-6457-3550</div>
          </div>
        </div>
        <div className="footerBar">
          <p>Web幹事</p>
          <p>
            株式会社ユーティル（〒160-0022 東京都新宿区新宿1-34-5 Verde
            Vista新宿御苑2階）
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
