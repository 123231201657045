import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";
import contact from "../image/contact.png";
import contact1 from "../image/contact1.png";
import contact2 from "../image/contact2.png";
import contact3 from "../image/contact3.jpg";
import contact4 from "../image/contact4.png";
import logo from "../image/logo.png";

class ContacUs extends Component {
  constructor(props) {
    super(props);
    this.routehome=this.routehome.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routehome(){
    let {history} = this.props;
    history.push('/');
  }

  render() {
    return (
      <React.Fragment>
        <div className="header-bar">
          <img src={logo} alt="" onClick={this.routehome}/>
        </div>
        <div className="contact-page">
          <div className="contain">
            <div className="row">
              <div className="col">
                <div className="title">
                  累計<span>25,000</span>件以上の紹介実績※
                </div>
                <div className="img">
                  <img src={contact} alt="" />
                </div>
                <div className="tip">
                  専属コンサルタントが最適な会社を選定します！
                </div>
                <div className="img">
                  <img src={contact1} alt="" />
                </div>
              </div>
              <div className="col">
                <div className="title">無料相談フォーム</div>
                <div className="second-head">
                  <div className="content">
                    <div className="li">
                      <div className="step"></div>
                      <div className="title">
                        step1
                        <br />
                        基本情報の入力
                      </div>
                    </div>
                    <div className="li">
                      <div className="step"></div>
                      <div className="title">
                        step2 <br />
                        相談内容の入力
                      </div>
                    </div>
                    <div className="li">
                      <div className="step"></div>
                      <div className="title">
                        step3 <br />
                        相談日時の選択
                      </div>
                    </div>
                    <div className="li">
                      <div className="step"></div>
                      <div className="title">
                        step4 <br />
                        送信完了
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-box">
                  <div className="title">
                    お名前 <span>必須</span>
                  </div>
                  <input type="text" placeholder="田中太郎" />
                </div>
                <div className="input-box">
                  <div className="title">
                    メールアドレス <span>必須</span>
                  </div>
                  <input type="text" placeholder="example@mail.com" />
                </div>
                <div className="input-box">
                  <div className="title">
                    お電話番号 <span>必須</span>
                  </div>
                  <input type="text" />
                </div>
                <div className="little-tip">
                  <span>利用規約 ・ プライバシーポリシー </span> <br />を
                  確認・同意のうえ、相談するボタンをクリックしてください
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContacUs;
