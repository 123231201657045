import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import ShoppingCart from "../icon/ShoppingCart";
import AddIcon from "../icon/AddIcon";
import LikeIcon from "../icon/LikeIcon";
import ShareIcon from "../icon/ShareIcon";
import sec1 from "../image/sec1.jpg";
import sec2_1 from "../image/sec2-1.png";
import sec2_2 from "../image/sec2-2.png";
import sec2_3 from "../image/sec2-3.png";
import sec3 from "../image/sec3.png";
import sec3_1 from "../image/sec3-1.png";
import sec4 from "../image/sec4.png";
import sec4_1 from "../image/sec4.jpg";
import sec4_2 from "../image/sec4-1.jpg";
import sec4_3 from "../image/sec4-2.jpg";
import logo_white from "../image/logo-white.png";
import sec6 from "../image/sec6.jpg";
import sec6_1 from "../image/sec6-1.jpg";
import sec6_2 from "../image/sec6-2.jpg";
import sec7 from "../image/sec7.png";
import sec7_1 from "../image/sec7-1.png";
import sec8 from "../image/sec8.jpg";
import sec8_1 from "../image/sec8-1.jpg";
import sec8_2 from "../image/sec8-2.jpg";
import sec8_3 from "../image/sec8-3.jpg";
import sec8_4 from "../image/sec8-4.jpg";
import sec9 from "../image/sec9.png";
import sec9_1 from "../image/sec9-1.png";
import sec9_2 from "../image/sec9-2.png";
import sec9_3 from "../image/sec9-3.png";
import sec9_4 from "../image/sec9-4.png";
import sec9_5 from "../image/sec9-5.png";
import sec9_6 from "../image/sec9-6.png";
import sec9_7 from "../image/sec9-7.png";
import sec9_8 from "../image/sec9-8.png";
import sec9_9 from "../image/sec9-9.png";
import sec10 from "../image/sec10.jpg";
import sec10_1 from "../image/sec10-1.jpg";
import sec10_2 from "../image/sec10-2.jpg";
import sec10_3 from "../image/sec10-3.jpg";
import sec10_4 from "../image/sec10-4.jpg";
import sec10_5 from "../image/sec10-5.jpg";
import sec10_6 from "../image/sec10-6.jpg";
import sec10_7 from "../image/sec10-7.jpg";
import sec10_8 from "../image/sec10-8.jpg";
import sec11 from "../image/sec11.png";
import sec11_1 from "../image/sec11-1.png";
import sec11_2 from "../image/sec11-2.png";
import sec11_3 from "../image/sec11-3.png";
import sec11_4 from "../image/sec11-4.png";
import sec12 from "../image/sec12.png";
import sec12_1 from "../image/sec12-1.png";
import sec12_2 from "../image/sec12-2.png";
import sec12_3 from "../image/sec12-3.png";
import sec12_4 from "../image/sec12-4.png";
import sec12_5 from "../image/sec12-5.png";
import sec12_6 from "../image/sec12-6.png";
import sec12_7 from "../image/sec12-7.png";
import sec12_8 from "../image/sec12-8.png";
import sec12_9 from "../image/sec12-9.png";
import sec12_10 from "../image/sec12-10.png";
import sec12_11 from "../image/sec12-11.png";
import sec13 from "../image/sec13.png";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routePage = this.routePage.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1">
            <img src={sec1} alt="" />
          </div>
          <div className="sec2">
            <div className="contain">
              <div className="input-box">
                <div className="title">
                  <img src={sec2_1} alt="" />
                </div>
                <div className="input-body">
                  <div className="left">
                    <div className="row">
                      <div className="title">
                        お名前 <span>必須</span>
                      </div>
                      <input type="text" placeholder="田中太郎" />
                    </div>
                    <div className="row">
                      <div className="title">
                        メールアドレス <span>必須</span>
                      </div>
                      <input type="text" placeholder="example@mail.com" />
                    </div>
                  </div>
                  <img src={sec2_2} alt="" className="btn" />
                </div>
              </div>
              <div className="img-box">
                <img src={sec2_3} alt="" />
              </div>
            </div>
          </div>
          <div className="sec3">
            <div className="title">インタビュー記事</div>
            <div className="row">
              <div className="col">
                <div className="img-box">
                  <img src={sec3} alt="" />
                </div>
                <div className="title">お客様インタビューを詳しく見る</div>
              </div>
              <div className="col">
                <div className="img-box">
                  <img src={sec3_1} alt="" />
                </div>
                <div className="title">制作会社インタビューを詳しく見る</div>
              </div>
            </div>
            <div className="sec-row">
              <div className="left">
                <img src={sec4} alt="" />
              </div>
              <div className="right-txt">
                <div className="title">幹事AWARD 2024</div>
                <p>
                  Web幹事・動画幹事・システム幹事にご登録いただいている、合計2,024企業(2024年9月時点)から、幹事AWARD委員会が自信を持ってお薦めする制作会社・開発会社を独自の基準で決定し表彰。部門ごとに受賞企業をご紹介いたします。
                </p>
              </div>
            </div>
          </div>
          <div className="sec4">
            <div className="title">
              ホームページ制作の依頼で、こんなお困りごとありませんか？
            </div>
            <div className="row">
              <div className="col">
                <div className="img-box">
                  <img src={sec4_1} alt="" />
                </div>
                <div className="title">ホームページの相場が分からない</div>
                <p>
                  制作を依頼したいが、どれくらいの金額がかかるのか分からない。
                  発注する前に相場の情報を知っておきたい。
                </p>
              </div>
              <div className="col">
                <div className="img-box">
                  <img src={sec4_2} alt="" />
                </div>
                <div className="title">どうやって 制作会社を選べばいいの？</div>
                <p>
                  自分で制作会社を検索してみたが、たくさん出てきて選べない。
                  そもそもどういう違いがあるのか分からない。
                </p>
              </div>
              <div className="col">
                <div className="img-box">
                  <img src={sec4_3} alt="" />
                </div>
                <div className="title">制作会社探しが面倒…</div>
                <p>
                  忙しくて制作会社を探している時間がない。
                  他の仕事もあるのに時間を使って探すのが面倒。
                </p>
              </div>
            </div>
          </div>
          <div className="sec5">
            <div className="title">
              そのお悩み、全て <img src={logo_white} alt="" />が 解決します！
            </div>
            <h2>Web幹事が制作会社への発注を無料で徹底支援！</h2>
            <p>
              Web幹事は日本最大級のホームページ制作の相談・依頼ができるサイトです。
              <br />
              専門のコンサルタントが要件を丁寧にヒアリングし、発注の準備から制作会社のご紹介まで{" "}
              <br />
              全て「無料」でご支援します！
            </p>
          </div>
          <div className="sec5-bottom"></div>
          <div className="sec6">
            <div className="row">
              <div className="col">
                <div className="img-box">
                  <img src={sec6} alt="" />
                </div>
                <div className="title">全国の制作会社の情報を収集</div>
                <p>
                  日本中の制作会社の情報を集め、集約したデータベースを保有。
                  地域や強みなど、様々な切り口で制作会社を探せます。
                </p>
              </div>
              <div className="col">
                <div className="img-box">
                  <img src={sec6_1} alt="" />
                </div>
                <div className="title">制作のプロが 貴社の要件をヒアリング</div>
                <p>
                  プロが「制作の目的」「ご希望の予算」などを丁寧にヒアリング。
                  Web制作の経験を持つコンサルタントが発注までサポートするため安心です。
                </p>
              </div>
              <div className="col">
                <div className="img-box">
                  <img src={sec6_2} alt="" />
                </div>
                <div className="title">要件にあった制作会社を 絞ってご紹介</div>
                <p>
                  貴社の目的に応じて、最適な制作会社をWeb幹事側で選定。
                  厳選した数社をご提案させていただきます。
                </p>
              </div>
            </div>
          </div>
          <div className="sec7">
            <div className="contain">
              <div className="title">
                時間も知識もなくて大丈夫！Web制作のプロが最適な制作会社をご紹介します！
              </div>
              <div className="row">
                <div className="col">
                  <div className="title">
                    貴社にぴったりな制作会社を <br />
                    全国5,000社以上のデータベースから探します！
                  </div>
                  <div className="under">
                    <img src={sec7} alt="" />
                    <p>
                      月間400件以上の相談実績！ <br />
                      初心者の方も安心してご相談ください！
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="title">
                    今なら「相場ガイドブック」プレゼント！
                  </div>
                  <div className="under">
                    <img src={sec7_1} alt="" />
                    <p>簡単1分！見積もりを依頼する</p>
                  </div>
                  <div className="bottom">お急ぎの方はこちら 03-6457-3550</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sec8">
            <div className="title">ホームページ制作会社を探す</div>
            <div className="contain">
              <div className="title">地域 からホームページ制作会社を探す</div>
              <div className="row">
                <div className="col">
                  <div className="img">
                    <img src={sec8} alt="" />
                  </div>
                  <p>東京都</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec8_1} alt="" />
                  </div>
                  <p>神奈川県</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec8_2} alt="" />
                  </div>
                  <p>大阪府</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec8_3} alt="" />
                  </div>
                  <p>愛知県</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec8_4} alt="" />
                  </div>
                  <p>福岡県</p>
                </div>
              </div>
              <div className="box">
                <p>北海道・東北</p>
                <p>
                  北海道 | 青森県 | 秋田県 | 山形県 | 岩手県 | 宮城県 | 福島県
                </p>
                <p>関東</p>
                <p>
                  東京都 | 神奈川県 | 埼玉県 | 千葉県 | 栃木県 | 茨城県 | 群馬県
                </p>
                <p>中部</p>
                <p>
                  愛知県 | 岐阜県 | 静岡県 | 三重県 | 新潟県 | 山梨県 | 長野県 |
                  石川県 | 富山県 | 福井県
                </p>
                <p>関西</p>
                <p>大阪府 | 兵庫県 | 京都府 | 滋賀県 | 奈良県 | 和歌山県</p>
                <p>中国・四国</p>
                <p>
                  岡山県 | 広島県 | 鳥取県 | 島根県 | 山口県 | 香川県 | 徳島県 |
                  愛媛県 | 高知県
                </p>
                <p>九州・沖縄</p>
                <p>
                  福岡県 | 佐賀県 | 長崎県 | 熊本県 | 大分県 | 宮崎県 | 鹿児島県
                  | 沖縄県
                </p>
              </div>
            </div>
            <div className="contain">
              <div className="title">特徴 からホームページ制作会社を探す</div>
              <div className="row">
                <div className="col">
                  <div className="img">
                    <img src={sec9} alt="" />
                  </div>
                  <p>LP制作に強い</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_1} alt="" />
                  </div>
                  <p>格安対応</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_2} alt="" />
                  </div>
                  <p>Webマーケティング・集客に強い</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_3} alt="" />
                  </div>
                  <p>採用サイトに強い</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_4} alt="" />
                  </div>
                  <p>コンテンツマーケティングに強い</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_5} alt="" />
                  </div>
                  <p>SEO対策に強い</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_6} alt="" />
                  </div>
                  <p>BtoBマーケティングに強い</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_7} alt="" />
                  </div>
                  <p>ブランディングに強い</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_8} alt="" />
                  </div>
                  <p>ECサイトに強い</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec9_9} alt="" />
                  </div>
                  <p>デザインに強い</p>
                </div>
              </div>
            </div>
            <div className="contain">
              <div className="title">目的 からホームページ制作会社を探す</div>
              <div className="row">
                <div className="col">
                  <div className="img">
                    <img src={sec10} alt="" />
                  </div>
                  <p>企業サイト</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec10_1} alt="" />
                  </div>
                  <p>サービスサイト</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec10_2} alt="" />
                  </div>
                  <p>採用サイト</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec10_3} alt="" />
                  </div>
                  <p>ECサイト</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec10_4} alt="" />
                  </div>
                  <p>ランディングページ</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec10_5} alt="" />
                  </div>
                  <p>キャンペーンサイト</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec10_6} alt="" />
                  </div>
                  <p>オウンドメディア</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec10_7} alt="" />
                  </div>
                  <p>多言語サイト</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec10_8} alt="" />
                  </div>
                  <p>WordPress</p>
                </div>
              </div>
            </div>
            <div className="contain">
              <div className="title"> 業界 からホームページ制作会社を探す</div>
              <div className="row">
                <div className="col">
                  <div className="img">
                    <img src={sec11} alt="" />
                  </div>
                  <p>不動産・マンション</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec11_1} alt="" />
                  </div>
                  <p>病院</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec11_2} alt="" />
                  </div>
                  <p>飲食店・レストラン</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec11_3} alt="" />
                  </div>
                  <p>美容室・サロン</p>
                </div>
                <div className="col">
                  <div className="img">
                    <img src={sec11_4} alt="" />
                  </div>
                  <p>税理士・会計士</p>
                </div>
              </div>
            </div>
          </div>
          <div className="sec7">
            <div className="contain">
              <div className="title">
                ホームページに関する知識もない…制作会社を探すのが面倒…という方へ
              </div>
              <div className="row">
                <div className="col">
                  <div className="title">
                    貴社にぴったりな制作会社を <br />
                    全国5,000社以上のデータベースから探します！
                  </div>
                  <div className="under">
                    <img src={sec7} alt="" />
                    <p>
                      月間400件以上の相談実績！ <br />
                      初心者の方も安心してご相談ください！
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="title">
                    今なら「相場ガイドブック」プレゼント！
                  </div>
                  <div className="under">
                    <img src={sec7_1} alt="" />
                    <p>簡単1分！見積もりを依頼する</p>
                  </div>
                  <div className="bottom">お急ぎの方はこちら 03-6457-3550</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sec9">
            <div className="title">ホームページ制作の依頼で重要なポイント</div>
            <div className="contain">
              <div className="title">
                ホームページ制作の相場や料金事情を知ろう
              </div>
              <p>
                ホームページ制作を相談・依頼するには、まず費用の相場を知ることが重要です。
                <br />
                知識がない中で、いきなり制作会社に見積もり依頼をするのは危険・・・
                <br />
                提案された見積金額が高いのか安いのか判断できる知識を身につけておくことが、スマートな発注の第一歩です。
              </p>
              <p>
                ホームページの相場は、目的やサイトの種類によってバラバラ。数万円で作成できるものから、{" "}
                <br />
                数百万円に上るものまで非常に多様です。
              </p>
              <p>自社で作成したいホームページの相場をまずは確認しましょう！</p>
            </div>
            <div className="contain">
              <div className="title">ホームページ制作の発注準備をしよう</div>
              <p>
                費用の相場を確認できたら、実際に依頼をする準備をしましょう。
                <br />
                ホームページは発注の準備が、プロジェクトの成果を大きく左右します。
                <br />
                しっかり準備すればするほど、制作会社とのやり取りもスムーズになりプロジェクトの成功確率も上がります！
              </p>
              <p>
                専門知識は不要です。自分のわかる範囲で丁寧に発注の準備をしましょう！
              </p>
            </div>
            <div className="contain">
              <div className="title">自社の目的にあった制作会社を探そう</div>
              <p>
                準備ができたら実際に、見積もり依頼をするホームページ制作会社を探しましょう！
                <br />
                Web幹事では、地域はもちろんのこと目的や特徴など、様々な確度から制作会社を検索することが可能です。
              </p>
              <p>
                「探している時間がない」「1つ制作会社を見るのが面倒」という方は{" "}
                <br />
                Web幹事のコンサルタントが最適な制作会社をご紹介します。
                <br />
                電話やメールで簡単な要件を伝えるだけで、優良制作会社を2~3社ご提案させていただきます。
              </p>
              <p>相談料はもちろん「無料」。お気軽にご相談ください！</p>
            </div>
          </div>
          <div className="sec10">
            <div className="contain">
              <div className="col">
                <p>まずは制作会社に見積もりを依頼</p>
                <div className="btn" onClick={()=>this.routePage('contact')}>お見積もりを依頼</div>
              </div>
              <div className="col">
                <p>お急ぎの場合はお電話でもご対応！</p>
                <div className="btn"> 03-6457-3550</div>
              </div>
            </div>
          </div>
          <div className="sec11">
            <div className="title">よくわかる！ホームページ制作ガイド</div>
            <p>「ホームページの制作依頼が初めて」という方はもちろん</p>
            <p>
              「より知識をつけて、良い制作会社に発注したい」という方も、ぜひご覧ください。
            </p>
            <div className="contain">
              <div className="col">
                <div className="left">
                  <img src={sec12} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b> ホームページの種類</b>
                  </p>
                  <p>
                    まずは自分がどのホームページを作成したいのかを見極めるのが重要です。
                    目的別に制作すべきホームページの種類についてご説明します。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_1} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>ホームページ制作会社の種類</b>
                  </p>
                  <p>
                    制作会社にも、その得意分野によって様々な種類が存在します。
                    制作会社の特徴を抑えて発注することが大きな一歩に繋がります。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_2} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>ホームページ制作の流れ・期間</b>
                  </p>
                  <p>
                    依頼準備から、実際にホームページを公開するまでの流れと期間の目安をわかりやすく解説。
                    事前知識として、おさえておきましょう。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_3} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>ホームページ制作のよくあるトラブル</b>
                  </p>
                  <p>
                    ホームページでよくあるトラブルをまとめました。
                    未然にトラブルを防ぐための対策までご紹介しています。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_4} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>ホームページ制作の仕様書とは？</b>
                  </p>
                  <p>
                    ホームページの仕様書って何を準備すればいいの？というお悩みに徹底的にお答えします。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_5} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>ホームページ制作でやってはいけないこと！</b>
                  </p>
                  <p>
                    ホームページ制作でやってしまいがちなミスについて解説。
                    納品物の品質確認のためのチェックリストとしてもご活用いただけます。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_6} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>業務委託契約のチェックポイント</b>
                  </p>
                  <p>
                    発注時の契約について、重要なポイントをピックアップして解説！
                    不利な条件で契約しないように事前に確認してきましょう。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_7} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>騙された！リース契約に注意</b>
                  </p>
                  <p>
                    費用を安く抑えたいがために、リース契約を結んでしまい取り返しのつかないことに・・・
                    リース契約の注意点・デメリットについて解説しています。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_8} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>依頼前に！デザインの参考事例</b>
                  </p>
                  <p>
                    業界別のデザインやホームページのパーツ別の参考事例を厳選！
                    参考デザインをお探しの方はぜひご覧ください。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_9} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>おすすめ制作会社インタビュー</b>
                  </p>
                  <p>
                    Web幹事に掲載されている制作会社にインタビューを敢行！
                    各会社の強みや事例・料金など他にはない情報に迫ります。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_10} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>地域別おすすめホームページ制作会社</b>
                  </p>
                  <p>
                    都道府県や都市別に、Web幹事がオススメしたい制作会社をピックアップしてご紹介。
                    発注先選びの参考にしてください。
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="left">
                  <img src={sec12_11} alt="" />
                </div>
                <div className="right">
                  <p>
                    <b>業界別おすすめホームページ制作会社</b>
                  </p>
                  <p>
                    不動産やクリニックなど業界別にオススメしたい制作会社をピックアップしてご紹介。
                    発注先選びの参考にしてください。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sec12">
            <div className="contain">
              <img src={sec13} alt="" />
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
