import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";
import aboutImg from "../image/about.png";
import aboutBg from "../image/about-bg.png";

class About extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
          <h1>Stockについて</h1>
          <h3>For Web Designers. For Your Clients</h3>
          <h3>Webデザイナーのために。あなたのクライアントのために。</h3>
          <p>
            世の中には素晴らしいデザインが溢れています。
            Stockではその中からWeb制作の現場で参考になる日本の優れたWebデザインを集めてみました。
          </p>
          <p>
            Webデザイナーは自身のインスピレーションを刺激し、
            あなたのクライアントは自社サイトへ取り入れたい新たなアイデアを発見できるはず。
          </p>
          <p>For Web Designers. For Your Clients.</p>
          <p>
            素晴らしいデザイナーやクリエイターが手掛けた最高のクリエイティブに出会えたことに感謝しつつ、
            ぜひあなたなりの最高のクリエイティブを生み出してください！
          </p>
          <h3>Stock運営者</h3>
          <p>小林 / フリーランスデザイナー</p>
          <p>
            Webサイトをメインに、さまざまなデザインをご提供しているフリーランスデザイナー。Twitterでは、Webサイト制作の現場で使えるTipsやアイデアを発信しています（Follower
            104,000 over）。
          </p>
          <p>デザイン制作 BUILD</p>
          <p>Twitter（X） https://twitter.com/pulpxstyle</p>
          <h3>掲載サイトについて</h3>
          <p>
            素晴らしいデザインを紹介する目的で運営しておりますが、掲載を希望されないサイト運営者もいらっしゃるかもしれません。
            もし掲載の取下げや内容の修正をご希望でしたら、お問い合わせフォームよりご連絡ください。
            できるかぎり早急に対応させていただきます。
          </p>
          <p>
            また、優れたWebデザインを制作された方やサイト運営者からの掲載希望を受け付けております。
            残念ながら埋もれてしまっている素晴らしいデザインがありましたら、自薦他薦問わずご連絡ください。
            ※当サイトの方針によりご期待に沿えられない場合もございます。予めご了承ください。
            Top Stockについて
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
