import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/privacy.scss";
import sec6_people from "../image/sec6-people.jpg";

class Shipping extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="shipping-page">
          <div className="left">
            <div className="title">利用規約</div>
            <p>
              本利用規約（以下「本規約」といいます。）には、ストリートアカデミー株式会社（以下「当社」といいます。）の提供するサービスのご利用にあたり、登録ユーザーの皆様に遵守していただかなければならない事項及び当社と登録ユーザーの皆様との間の権利義務関係が定められております。当該サービスを登録ユーザーとしてご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
            </p>
            <p>第1章　総則</p>
            <p>
              第1条　適用 1.
              本規約は、本サービス（第2条に定義）の利用に関する当社と登録ユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、登録ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
              2.
              登録ユーザーには生徒（第2条に定義）としての登録ユーザーと、先生（第2条に定義）としての登録ユーザーが存在します。本規約の第1章及び第4章の規定は全ての登録ユーザーに適用されますが、第2章は生徒としての権利義務にのみ、第3章は先生としての権利義務にのみ適用されるものとします。なお、生徒と先生の両方の立場において本サービスを利用することも可能であり、その場合は第2章及び第3章の双方の規定が適用されます。
              3.
              本サービスは、生徒が興味をもった講座を探して受講予約をし、先生が自身の開催する講座に関心を持つ生徒を対象とした講座開催告知及び参加募集を可能にするプラットフォームを提供するものであり、当社は、先生と生徒間の問題に関し、一切の責任を負わないものとします。
              4.
              当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
            </p>
            <p>
              第2条　定　義
              本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
              (1)
              「外部SNSサービス」とは、Facebook、その他の他の事業者が提供している当社所定のソーシャル・ネットワーキング・サービスで、登録ユーザーの認証、友人関係の開示、当該外部ソーシャル・ネットワーク内へのコンテンツの公開などの機能を持ち、本サービスの実施に利用されるサービスを意味します。
              (2)
              「外部SNS事業者」とは、外部SNSサービスのサービス提供者を意味します。
              (3)
              「外部SNS利用規約」とは、登録ユーザーと外部SNS事業者との権利関係を定める規約を意味します。
              (4)
              「講座ページ」とは、本サービス上において先生が提供する講座の内容を記載したウェブページのことを意味します。
              (5)
              「講座」とは、対面あるいはオンラインの方法にて、先生が講座ページにて宣言した日時に受講契約を結んだ生徒を集め、講義又はワークショップのいずれかを執り行う場を意味します。
              (6)
              「受講契約」とは、第7条第2項に定義する「受講契約」を意味するものとします。
              (7)
              「受講料」とは、第7条第3項に定義する「受講料」を意味するものとします。
              (8)
              「生徒」とは、生徒として本サービスに掲載されている講座や月額サービスに参加すること及び依頼サービスを受けることを目的として利用している登録ユーザーを意味します。
              (9)
              「先生」とは、先生として講座又は月額課金及び依頼機能によるサービスを提供する登録ユーザーを意味します。
              (10)
              「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
              (11)
              「手数料」とは、先生が本サービスの対価として当社に支払う手数料を意味するものとします。
              (12)
              「当社ウェブサイト」とは、そのドメインが「www.street-academy.com/」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
              (13)
              「登録希望者」とは、第3条において定義された「登録希望者」を意味します。
              (14)
              「登録情報」とは、第3条において定義された「登録情報」を意味します。
              (15)
              「登録ユーザー」とは、第3条に基づき本サービスの利用者としての登録がなされた個人又は法人を意味します。
              (16)
              「ポイント」とは、第13条第1項に定義する「ポイント」を意味するものとします。
              (17)
              「本サービス」とは、生徒が興味をもった講座等を探して予約をし、先生が自身の開催する講座等に関心を持つ生徒を対象とした講座等の告知及び参加募集を可能にするストアカという名称のサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
              (18)
              「月額サービスページ」とは、本サービス上において先生が提供する月額サービスの内容を記載したウェブページのことを意味します。
              (19)
              「月額サービス」とは、本サービス上において先生が加入者に対し提供する月額サービスを意味します。また、先生においては当社が提供する月額課金の決済サービスをも意味します。
              (20)
              「依頼ページ」とは、本サービス上において先生が提供する依頼サービスの内容を記載したウェブページのことを意味します。
              (21）「依頼サービス」とは、本サービス上において先生が依頼購入者に対して提供する時間制相談及びカスタマイズレッスンを意味します。
              (22）「依頼機能」とは、依頼サービスのための募集及び決済サービスとして当社が提供する機能を意味します。
              (23)
              「月額料金」とは、第9条第3項に定義する「月額料金」を意味するものとします。
              (24)
              「加入者」とは、先生が提供する各月額サービスに申し込んだ生徒のことを指します。
              (25）「依頼購入者」とは依頼ページから先生が提供する依頼サービスのチケットを購入した生徒のことを指します。
              (26）「依頼チケット」とは、特定の依頼サービスの提供を受けることができる証票であって、依頼サービスの提供を受けた時点で消費される電磁的な記録を意味します。
              (27)
              「依頼料金」とは、第11条第1項に定義する「依頼料金」を意味するものとします。
              (28）「講座等」とは講座、月額サービス及び依頼サービスの総称を意味します。
              (29）「講座等のページ」とは講座ページ、月額サービスページ及び依頼ページの総称を意味します。
              (30)
              「利用契約」とは、第3条第5項に基づき当社と登録ユーザーの間で成立する、本規約及びそれに付随して適用されるその他一切の規則又は規約等（「ストアカ講座受講ガイドライン」、「月額サービスガイドライン」「依頼ガイドライン」「ストアカ先生ガイドライン」、「ストアカ主催団体ガイドライン」、「ご利用ガイド」、「ストアカレビュー投稿ガイドライン」及びプライバシーポリシーを含みますが、これらに限りません）の諸規定に従った本サービスの利用規約の総称を意味します。
              (31)
              「連絡」とは、本サービス上の関係において生徒と先生の間で行われるコミュニケーション行為全般を意味します。
            </p>
            <p>
              第3条 登録 1.
              本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約及び当社が定める各ガイドラインを遵守することに同意し、かつ、プライバシーポリシーが適用されることに同意したものとみなします。また、当社が定める一定の情報（以下「登録情報」といいます。）を当社が定める方法で当社に提供することにより本サービスの利用の登録を申請することができます。
              2.
              登録の申請は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
              3.
              本サービスを利用する登録ユーザーは、1つのアカウントを利用するものとし、複数のアカウントを登録及び保有してはならないものとします。
              4.
              当社は、当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知し、この通知により登録希望者の登録ユーザーとしての登録は完了したものとします。
              5.
              前項に定める登録の完了時に、本規約の諸規定に従った本サービスの利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを当社の定める方法で利用することができるようになります。
              6.
              本サービスの利用にあたり、当社は登録ユーザーに対して当社所定の方法により本人確認手続きを依頼する場合があります。本人確認手続きが完了しない又は本人確認手続きが非承認とされた場合には、当社は当該登録ユーザーの本サービスの利用の一部又は全部を停止、又は登録ユーザーとしての登録を取り消すことができます。当社は、本項の停止又は登録取消により登録ユーザーに発生した損害について一切の責任を負わないものとします。また、登録ユーザーは本人確認手続きに際して、当社が本人確認業務に関する当社の委託業者（株式会社TRUSTDOCK）へ本人確認情報を提供することについて予め同意するものとします。当該本人確認情報は当社と委託業者で取り決めた契約に基づき取り扱われます。なお、本人確認業務の委託に伴う個人情報の取り扱いについては、委託業者のプライバシーポリシー（
              https://biz.trustdock.io/privacy/
              ）の「取引先から委託された個人情報及び特定個人情報」をご参照下さい。
              7.
              当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
              (1) 本規約に違反するおそれがあると当社が判断した場合 (2)
              当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
              (3) 過去に本サービスの利用の登録を取り消された者である場合 (4)
              未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
              (5)
              反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
              (6) その他、当社が登録を適当でないと判断した場合
            </p>
            <p>
              第4条 登録情報の変更
              登録ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
            </p>
            <p>
              第5条 パスワード及びユーザーIDの管理 1.
              登録ユーザーは、自己の責任において、パスワード及びユーザーIDを管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
              2.
              パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。
              3.
              登録ユーザーは、パスワード又はユーザーIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
            </p>
            <p>
              第6条 プロフィールの公開
              登録ユーザーは、自らのプロフィールについて、「公開」に設定した箇所については、第三者（登録ユーザーを含みますが、これに限られません。）に公開がなされること及び本サービスにおいては、デフォルトで全てのプロフィールが公開される設定となっていることに予め同意するものとします。当社は、本項の公開により登録ユーザーに発生した損害について一切の責任を負わないものとします。
            </p>
            <p>第2章　生徒</p>
            <p>
              第7条 講座の受講 1.
              生徒は、当社所定の方法により、講座を予約することができます。 2.
              前項の予約手続が完了した場合、生徒に対し予約完了の通知が行われるものとし、当該通知が生徒に到達した時点で、先生と生徒の間に生徒が予約した講座の受講に関する契約（以下「受講契約」といいます。）が成立するものとします。但し、生徒の責に帰すべき事由により当該通知が到達しなかった場合には、通常到達すべき時点において当該通知が到達したものとみなして受講契約が成立するものとします。
              3.
              生徒は、前項の通知の定めるところに従い、前項に基づき受講契約が成立した講座を受講する対価（以下「受講料」といいます。）を当社の指定する方法で当社に支払うものとします。振込手数料その他支払に必要な費用は生徒の負担とします。
              4.
              生徒が受講料の支払を遅滞した場合、生徒は年14.6％の割合による遅延損害金を当社に支払うものとします。
            </p>
            <p>
              第8条　講座のキャンセル及び異議の申し出 1.
              生徒は、予約締切日までは予約をキャンセルすることができるものとし、キャンセルされた講座に関する受講契約は取り消されるものとします。この場合、当社は、当社が別途定めるところに従い、受講料を生徒に返還しますが、生徒は、返還される金額から返還に際して当社が負担した費用が控除される場合があることに、予め同意するものとします。予約締切日を経過した場合には、生徒は予約をキャンセルすることはできないものとします。受講料の返還方法は支払い方法によって異なり、現金での返還が不可能な場合もあることに、予め同意するものとします。
              2.
              生徒は、講座が開催されてから1週間以内に限り、講座の提供についての異議を当社に対して述べることができるものとします（以下、本条において当該期間を「異議申出期間」といいます。）。生徒がかかる異議を述べた場合において、先生が講座の提供を怠っていた等、当社が生徒の異議に理由があると認める場合には、当社が別途定めるところに従い、生徒が支払った受講料を生徒に返還します。この場合、生徒は、返還される金額から返還に際して当社が負担した費用が控除される場合があることに、予め同意するものとします。異議申出期間中に生徒から異議が述べられなかった場合には、先生が適切な講座の役務提供を行ったものとみなし、以後生徒は異議を述べることはできないものとします。
            </p>
            <p>
              第9条　月額サービスの加入 1.
              生徒は、当社所定の方法により、月額サービスに申し込むこと（以下「加入」といいます。）ができます。
              2.
              前項の加入手続きが完了した場合、生徒に対し、加入の完了の通知が行われるものとし、当該通知が加入を希望する者に到達した時点で、加入をした月額サービスページに記載のあるサービス内容を提供することを内容とする契約（以下「月額サービス契約」といいます。）が先生と生徒の間に成立するものとします。但し、生徒の責に帰すべき事由により当該通知が到達しなかった場合には、通常到達すべき時点において当該通知が到達したものとみなして月額サービス契約が成立するものとします。
              3.
              生徒は、前項に基づき成立した月額サービス契約の月額料金を当社の指定する方法で当社に前払いにて支払うものとします。
              4.
              前項の支払いが完了した時点で生徒は当該月額サービスの「加入者」となり、加入者は加入手続きが完了した日（以下「加入日」といいます。）より有効期間まで当該月額サービスを利用することができます。
              5.
              月額サービスの有効期間は加入日から1ヶ月間とします。（以下「月額サービスの有効期間」といいます。）
              6.
              月額サービス契約は、加入者が加入中の月額サービスの有効期間内に当社所定の方法による自主解約、又はその他の事由によって加入資格が失効しないかぎり、原則1ヶ月ごと自動的に更新するものとします。（以下「自動更新」といいます。）
              7.
              加入者は、当該月額サービスを利用するにあたり、管理者である先生の監督及び指示、注意事項に従い、健全な利用をすることを約束するものとします。先生が提供するサービスを利用して、先生あるいは他の加入者に対し第24条の禁止行為は一切行わないものとします。
            </p>
            <p>
              第10条　月額サービスの解約 1.
              生徒は当社所定の方法にていつでも月額サービスの解約手続きを行うことができます。
              2.
              生徒が月額サービスの解約手続きを完了した日（以下「月額サービスの解約日」といいます）をもって、月額サービス契約は終了し、生徒は有効期間の残日数に関わらず当該サービスの提供を受ける権利を失うものとします。
              3.
              生徒の月額サービスの解約に際し、有効期間の残日数に関わらず、当社あるいは先生は支払われた月額料金の全額又は一部を返金しないものとします。
              4.
              生徒は、申し込む月額サービスの内容及び課金サイクルについて十分に理解した上で月額サービスに加入したものとみなします。よって、申込み完了後（自動更新後も含む）はいかなる事由においても当社及び先生は、支払われた月額料金の全額又は一部の返金には一切応じないものとします。
              5.
              加入中の月額サービスの解約は、原則加入者自身が当社所定の方法にて解約手続きをおこなうものとします。生徒側にて解約手続きが行なえない等の事由により、自動更新となった場合でも、加入者は当社あるいは先生に月額料金の返金を要求できないものとします。
              6.
              当社又は先生から加入中の月額サービスのサービス終了の連絡があった場合は、生徒は期日までに解約手続きを行うものとします。期日までに解約が確認できない場合は、当社又は先生による当該期日での解約手続きに同意したものとみなされます。
              7.
              当社所定の期日までに当社が生徒の月額料金の支払を確認できない場合は、当該生徒は当該期日に月額サービスを解約したものとみなします。
              8.
              当該月額サービスを提供する先生が一定期間連絡が取れなくなった等、当該月額サービスの継続した提供が不可能と当社が判断した場合は、当社の裁量により、加入者の当該月額サービスの解約手続きを当社が行う場合があります。当社が解約手続きを行ったことにより生徒に損害が生じた場合でも、当社は当該損害において一切の責任を負わないものとします。
            </p>
            <p>
              第11条　依頼サービス（時間制相談又はカスタマイズレッスン）の購入
              1.
              生徒は、当社所定の方法により依頼チケットの料金（以下「依頼料金」といいます。）を支払うことにより、先生から、依頼チケットを購入することができます。
              2.
              依頼チケットを購入した生徒は、依頼料金を当社の指定する方法で当社に前払いにて支払うもとのします。
              3.
              生徒が依頼料金の支払を遅滞した場合、生徒は年14.6％の割合による遅延損害金を当社に支払うものとします。
              4. 依頼チケットを購入した時点で生徒は「依頼購入者」となります。 5.
              依頼購入者は、依頼チケットの有効期限中、本サービス上で先生と依頼サービスを受ける日程の調整を行い、合意した日程において先生から依頼ページ及び各ガイドラインに定められた依頼サービスの提供を受けることができ、これにより当該依頼サービスにかかる依頼チケットが消費されるものとします。
              6.
              依頼購入者においても、先生が第21条及び各ガイドラインで禁止されている内容を提案又は要求することを禁止します。
              7.
              依頼チケットを購入した日又は依頼購入者が日程調整の連絡をした日のいずれか遅い日から一週間経過しても先生から何らの連絡がない場合、又は先生が依頼ページに記載の内容と著しく異なる依頼サービスを提供しようとし、双方の協議による解決が困難な場合、生徒は当社に対して当社所定の方法で連絡するものとします。先生による依頼サービスの提供が困難と当社が認める場合には、当社が別途定めるところに従い、依頼チケットと引き換えに生徒が支払った依頼料金を生徒に返還します。この場合、生徒は、返還される金額から返還に際して当社が負担した費用が控除される場合があることに、予め同意するものとします。
              8.
              依頼サービスの提供を完了したと先生が判断し完了報告の手続きが行われた場合、当社は生徒に完了報告を通知します。完了報告の通知が到達した日又は依頼チケットの有効期限（第12条で定義）の末日のいずれか早い日より1週間以内に限り、生徒は依頼サービスの提供についての異議を当社に対して述べることができるものとします（以下、本条において当該期間を「異議申出期間」といいます。）。生徒がかかる異議を述べた場合において、先生が依頼サービスの提供を怠っていた等、当社が生徒の異議に理由があると認める場合には、依頼チケットは消費されず、当社は当社が別途定めるところに従い、生徒が支払った依頼料金を依頼チケットと引き換えに生徒に返還します。この場合、生徒は、返還される金額から返還に際して当社が負担した費用が控除される場合があることに、予め同意するものとします。異議申出期間中に生徒から異議が述べられなかった場合には、以後生徒は異議を述べることはできないものとします。
            </p>
            <p>
              第12条　依頼チケットの有効期限とキャンセル 1.
              依頼チケットの有効期限は依頼チケットを購入した日の翌日から180日とします。（以下「依頼チケットの有効期限」といいます。）
              2.
              依頼チケットの有効期限を過ぎた場合は、依頼チケットを消費して依頼サービスの提供を受ける権利が失効し、以後生徒は当該依頼チケットを利用することは出来ません。その場合であっても、当社は生徒に対し、依頼料金の返金は行いません。
              3.
              生徒はチケット購入前に各ガイドライン及び依頼ページの記載事項を確認し、疑問がある場合は先生へ質問し内容を理解した上で、自己の責任で依頼チケットを購入するものとします。依頼チケット購入後は、先生からのみ、ガイドラインに基づいた依頼料金の返金が出来るものとし、依頼購入者からの依頼チケット購入のキャンセルは原則出来ません。
              なお、先生から当社に依頼料金の返金の申し出があった場合は、当社が別途定めるところに従い、依頼チケットと引き換えに生徒が支払った依頼料金を生徒に返還します。この場合、生徒は、返還される金額から返還に際して当社が負担した費用が控除される場合があることに、予め同意するものとします。
            </p>
            <p>
              第13条　ポイント 1.
              当社は、当社の定めるところにより、受講料に充当することができることや受講料の割引を受けることができるポイント（以下、単に「ポイント」といいます。）を発行する場合があります。ポイントは有償で発行する場合及び無償で発行する場合があります。ポイントの価格、付与の条件その他の内容については、当社が個別に決定するものとします。
              2.
              ポイントは返品することができないものとし、生徒はいかなる場合でも当社に対し、払い戻しを請求することはできないものとします。また、生徒は、ポイントを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
              3.
              ポイントの具体的な使用期限については個々のポイント発行の際に当社が決定するものとします。なお、有償で発行するポイントの使用期限についてはいかなる場合でも180日を超えることはないものとします。
            </p>
            <p>
              第14条　生徒の義務等 1.
              生徒は、先生の合理的な指示に従って、自らが予約した講座等に誠実に参加しなければならないものとします。
              2.
              生徒は、当社から講座等に関する報告を求める旨の合理的な請求があった場合には、当該請求に従って回答するものとします。
              3.
              生徒は、先生が講座等を提供するために必要な範囲で、当社から先生に対して登録情報の全部又は一部が提供される場合があることに予め同意し、これについて一切の異議を述べないものとします。
              4.
              生徒は、先生が特に許可した場合において、自らの子供その他の第三者と共に講座等の提供を受ける場合、当該第三者が先生の指示に従って講座等を受講するよう適切な措置をとるものとし、かつ、当該第三者の行為について一切の責任を負うものとします。
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Shipping;
